const BlogContent = [
    {
        images: '01',
        title: 'What is the Difference.',
        content: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    {
        images: '01',
        title: 'A big ticket gone.',
        content: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    {
        images: '01',
        title: 'Getting tickets.',
        content: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    },
    {
        images: '04',
        title: ' Getting tickets to the big show',
        content: 'Development'
    },
    {
        images: '05',
        title: 'A big ticket gone to be an interesting ',
        content: 'Management'
    },
    {
        images: '06',
        title: 'The Home of the Future Could Bebes',
        content: 'Design'
    },
    {
        images: '07',
        title: 'The Home of the Future Could Bebes',
        content: 'Design'
    },
]

export default BlogContent;